import { Link } from "gatsby";
import { isEmpty } from "lodash";
import * as React from "react";
import { Button, Form, Container, Row, Col, Breadcrumb } from "react-bootstrap";
import VideoCard from "../VideoCard/VideoCard";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import Slider from "react-slick";
import "./PhotoGallery.scss";
import galleryImg01 from "../../../images/module/photo-gallery01.jpg";
import galleryImg02 from "../../../images/module/photo-gallery02.jpg";
import ScrollAnimation from "react-animate-on-scroll";

const PhotoGallery = (props) => {

    let propertyData = props?.propertyData

    const settings1 = {
        dots: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        mobileFirst:true,
        centerMode: false,
        variableWidth: true,
        centerPadding: '18px',

      }

      if (isEmpty(propertyData.images)) {
          return <></>
      }
    return (
        <div className="customer-gallery">
            <div className="gallery-wrap">
                <div className="stories-head">
                    <ScrollAnimation animateIn="fadeInUp" animateOnce>
                        <h2 className="main-title">Photo Gallery</h2>
                    </ScrollAnimation>
                </div>

                <Slider className="banner-imgslider stories-slider d-block" {...settings1}>
                    {propertyData.images && propertyData.images.map((node, i) => {
                            let processedImages = JSON.stringify({});
                            if (propertyData.imagetransforms?.images_Transforms) {
                                processedImages = propertyData.imagetransforms.images_Transforms;
                            }
                            return (
                                <div className="img-item first-slide" key={i}>
                                    <VideoCard
                                        cardTitle={"Emily Jenkinson"}
                                        cardImg={
                                            <ImageTransform
                                                imagesources={node.url}
                                                renderer="srcSet"
                                                imagename="new-developments.images.detail"
                                                attr={{ alt: propertyData?.project + ", Off plan - Espace", className: '' }}
                                                imagetransformresult={processedImages}
                                                id={propertyData.id}
                                            />
                                        }
                                        searchLink={"first time buyer"}
                                    />

                                </div>
                            )
                        })
                    }
                </Slider>

                <div className="property-btns enlarge-btn">
                    <Button type="button" variant="link" className="photos_box" onClick={(e) => {props?.enlargeFun(e, 1)}}>
                        <i className="icon-enlarge"></i><span className="d-none d-md-block"> Enlarge</span>
                    </Button>
                </div>
            </div>
        </div>
    );
};
export default PhotoGallery;
