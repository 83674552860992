import { Link } from "gatsby";
import * as React from "react";
import "./MediaList.scss";
import ScrollAnimation from "react-animate-on-scroll";
import { Modal } from "react-bootstrap";

const MediaList = ({ showVideo, playVideo, enlargeImgFun, enlargeImgFloorFun, ...props}) => {
    const [mpModal, setMpModal] = React.useState(false);

    let propertyData = props?.propertyData

    return (
        <div className="media-download">
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                <div className="stories-head">
                    <h2 className="main-title">Media &amp; Downloads</h2>
                </div>
                <ul className="media-list d-md-flex flex-wrap justify-content-start">
                    {propertyData?.brochure?.url && (
                        <li className="mx-1">
                            <a href={propertyData?.brochure?.url} target="_blank" className="text-uppercase"><i className="icon-brochure-red"></i> brochure</a>
                        </li>
                    )}
                    {(enlargeImgFloorFun && propertyData.floorplan.length > 0) && (
                        <li className="mx-1">
                            <a href="javascript:;" onClick={(e) => { enlargeImgFloorFun(e, true)}} className="text-uppercase"><i className="icon-plan-red"></i> floorplans</a>
                        </li>
                    )}
                    {(propertyData.masterplan && propertyData.masterplan.length > 0) && (
                        <li className="mx-1">
                            <a href="javascript:;" onClick={(e) => setMpModal(true)} className="text-uppercase"><i className="icon-plan-red"></i> masterplan</a>
                        </li>
                    )}
                    {props?.data?.video && (
                        <li className="mx-1">
                            <a href="javascript:;" className="text-uppercase" onClick={(e) => { e.preventDefault(); playVideo(); showVideo(true)}}><i className="icon-video-red"></i> video tour</a>
                        </li>
                    )}
                    {(propertyData.latitude && propertyData.longitude) && (
                        <li className="mx-1">
                            <a href={`https://maps.google.com/maps?q=${propertyData.latitude},${propertyData.longitude}`} target="_blank" className="text-uppercase"><i className="icon-map-red"></i>interactive map</a>
                        </li>
                    )}
                </ul>
            </ScrollAnimation>
            <Modal size="xl" show={mpModal} onHide={() => setMpModal(false)} centered className="contact-form-sec">
                <div className="contact-form subsribe-form">
                    <Modal.Header closeButton>
                        <Modal.Title as="h2">Master Plan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <img src={propertyData?.masterplan[0]?.url} alt={propertyData?.masterplan[0]?.alternativeText} className="w-100"/>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    );
};
export default MediaList;
