import React, { useState, useEffect, useCallback } from "react"
import { get, isEmpty } from "lodash"
import { getPidFromUrl } from "@StarberryUtils"
import { Link } from "gatsby"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { Button, Container, Modal } from "react-bootstrap"
import Slider from "react-slick"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import ScrollAnimation from "react-animate-on-scroll"
import $ from "jquery"
import { useLocation } from "@reach/router"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import KeyFeature from "../components/PropertyDetails/KeyFeature/KeyFeature"
import Amenities from "../components/PropertyDetails/Amenities/Amenities"
import Payment from "../components/AllModules/PaymentPlan/PaymentPlan"
import PhotoGallery from "../components/AllModules/PhotoGallery/PhotoGallery"
import PropertyContact from "../components/PropertyDetails/PropertyContact/PropertyContact"
import MediaList from "../components/AllModules/MediaList/MediaList"
import MortgageCalculator from "../components/MortgageSection/MortgageCalculator";
import PropertyValuation from "../components/PropertyDetails/PropertyValuation/PropertyValuation"
import ImageTransform from "../components/common/ggfx-client/module/components/image-transform"

import AgentImg from "../images/profile-pic.jpg"
import Loader from "../components/loader"
import SimilarProperties from "../components/PropertyDetails/SimilarProperties/SimilarProperty"
import GoogleMapReact from "google-map-react"
import markerImg from "../components/Map/images/map_marker.png"
import BookAViewing from "../components/forms/book-a-viewing"
import { getVideoId, removePlus } from "../components/utils"
import PlayVideo from "../components/Play/PlayVideo"
import SendFriendFormPage from "../components/forms/send-friend-form"
import "../components/PropertyDetails/PropertyDetails.scss"
import SEO from "../components/seo"

const Marker = () => {
  return <img src={markerImg} />
}

const PROPERTY_DETAILS = gql`
  query PropertyDetails($id: ID!) {
    newDevelopment(id: $id) {
      id
      developer
      project
      area
      title
      display_address
      search_type
      status
      price
      bedroom
      latitude
      longitude
      description
      additional_description
      video
      images {
        id
        name
        url
      }
      masterplan {
        url
        alternativeText
      }
      imagetransforms
      floorplan {
        id
        name
        url
      }
      brochure {
        id
        name
        url
      }
      installment_1
      installment_2
      installment_10
      installment_9
      installment_8
      installment_7
      installment_6
      installment_5
      installment_4
      installment_3
      payment_date_1
      payment_date_10
      payment_date_2
      payment_date_3
      payment_date_4
      payment_date_5
      payment_date_6
      payment_date_7
      payment_date_8
      payment_date_9
      teams {
        Email
        Name
        Phone
      }
    }
    siteConfig {
      Office_Phone
      Office_Email
      Office_Whatsapp
    }
  }
`

const videoRef = React.createRef(null)

const PropertyDetailsTemplate = props => {
  // Property details image count
  const [indexed, setIndex] = useState(1)

  // Property images lightbox
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [emailModal, setEmailModal] = React.useState(false)
  const [property_name, setPropertyName] = React.useState(null)
  const [property_url, setPropertyUrl] = React.useState(null)
  const [friendEmailModal, setFriendEmailModal] = React.useState(false)
  const [property_id, setPropertyId] = React.useState(null)
  const [email, setEmail] = React.useState(null)

  const openPropertyImage = (e, ind) => {
    e.preventDefault()
    setPhotoIndex(ind)
    setIsOpen(true)
  }
  // Property images lightbox

  // Property floorplan images lightbox
  const [photoIndexFloorplan, setPhotoIndexFloorplan] = useState(0)
  const [isOpenFloor, setIsOpenFloor] = useState(false)

  const [video, showVideo] = useState(false)
  const [video360, show360Tour] = useState(false)

  const openPropertyImageFloor = (e, ind) => {
    e.preventDefault()
    setPhotoIndexFloorplan(ind)
    setIsOpenFloor(true)
  }
  // Property floorplan images lightbox

  // Slider
  const settings = {
    dots: false,
    speed: 800,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          autoplay: false,
        },
      },
    ],
  }
  // Slider

  const { loading, error, data } = useQuery(PROPERTY_DETAILS, {
    variables: { id: getPidFromUrl() },
  })

  // scroll header
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 900 && !scroll) {
        setScroll(true)
      } else {
        setScroll(false)
      }
    })

    // property details scroll menu
    var propertySelector = ".property-details-menu-list li a"

    $(propertySelector).on("click", function () {
      $(propertySelector).removeClass("active")
      $(this).addClass("active")
    })

    document.addEventListener("fullscreenchange", fullScreenHandler, false)
    document.addEventListener(
      "webkitfullscreenchange",
      fullScreenHandler,
      false
    )
    document.addEventListener("MSFullscreenChange", fullScreenHandler, false)
    document.addEventListener("mozfullscreenchange", fullScreenHandler, false)
  }, [])
  // scroll header

  // property details scroll menu
  const propertyAmenities = e => {
    $("html, body").animate(
      {
        scrollTop:
          $("#property-amenities").offset().top -
          $(".property-details-menu-wrapper").height(),
      },
      100
    )
  }

  const propertyDescription = e => {
    $("html, body").animate(
      {
        scrollTop:
          $("#property-description").offset().top -
          $(".property-details-menu-wrapper").height(),
      },
      100
    )
  }

  const propertyLocation = e => {
    $("html, body").animate(
      {
        scrollTop:
          $("#property-details-map").offset().top -
          $(".property-details-menu-wrapper").height(),
      },
      100
    )
  }
  // property details scroll menu

  // Social share
  const location = useLocation()

  const shareurl = typeof window !== "undefined" ? window.location.href : ""

  const [Shareicons, setShareicons] = React.useState(false)

  const openShareicons = () => {
    setShareicons(true)
    if (Shareicons === true) {
      setShareicons(false)
    }
  }

  const [modalBookViewformOpen, setBookViewingformOpen] = React.useState(false)

  const openBookViewformModal = () => {
    setBookViewingformOpen(true)
  }

  const trackerShare = event => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "Share - social icons",
      formType: event + " - " + shareurl,
      formId: "Share",
      formName: "Share",
      formLabel: "Share",
    })
  }
  // Social share

  let processedImgs = JSON.stringify({})
  var propertyLightImages = []
  var propertyLightImagesFloorplan = []

  if (data && data.newDevelopment) {
    // ggfx
    if (data.newDevelopment?.imagetransforms?.images_Transforms) {
      processedImgs = data.newDevelopment.imagetransforms.images_Transforms
    }
    // Property details images lightbox
    const propertyImages = data.newDevelopment.images
    propertyLightImages = propertyImages && propertyImages.map(img => img.url)
    // Property details images lightbox

    // Property details floorplan images lightbox
    const floorplanImages = data.newDevelopment.floorplan
    propertyLightImagesFloorplan =
      floorplanImages && floorplanImages.map(img => img.url)
  }

  const negotiators = get(data, "newDevelopment.teams", [])
  const negotiator = get(negotiators, "[0]", {}) // get only first team member to show contact details

  const playVideo = () => {
    var elem = videoRef.current
    // const fullScreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled;
    // if(fullScreenAvailable) {
    //     if (elem.requestFullscreen) {
    //         elem.requestFullscreen();
    //     } else if (elem.webkitRequestFullscreen) { /* Safari */
    //         elem.webkitRequestFullscreen();
    //     } else if (elem.msRequestFullscreen) { /* IE11 */
    //         elem.msRequestFullscreen();
    //     }
    // } else {
    //     elem.classList.add("fullscreen-video")
    // }
    elem.classList.add("fullscreen-video")
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "formClick",
      formType: "form-video",
      formId: "local-living-video",
      formName: "Local Living Video",
      formLabel: window.location.href,
    })
  }

  const closeVideo = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen()
    }
    showVideo(false)
  }

  const priceQualifier = (Qualifier, price) => {
    if (Qualifier === "Yes") {
      return "Ask for price ";
    }
    return `AED ${price.toLocaleString()} `;
  }

  function fullScreenHandler() {
    var fullscreenElement =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement
    if (fullscreenElement == null) {
      showVideo(false)
    }
  }

  return (
    <React.Fragment>
      <SEO
        title={`New ${data?.newDevelopment?.title?.toLowerCase()}`}
        description={`Find the details of new ${data?.newDevelopment?.title?.toLowerCase()} with Espace real estate at AED ${
          data?.newDevelopment?.price
        }. Contact our agent to get assistance in knowing more.`}
        image={data?.newDevelopment?.images?.[0]?.url}
        location={props.location}
      />
      <div className="wrapper wrapper-static">
        <Header />
        {loading && (
          <div className="mt-5 pt-5 h-100">
            {" "}
            <Loader />
          </div>
        )}
        {!loading && data.newDevelopment && (
          <div className="search-list-block property-detail-block offplan-detail">
            <div className="search-item-box">
              <div className="d-xl-flex justify-content-between">
                <div className="search-property-img">
                  <Slider
                    className="card-slider d-block"
                    {...settings}
                    afterChange={v => {
                      if (v != 0) {
                        setPhotoIndexFloorplan(v)
                      }
                    }}
                  >
                    {data.newDevelopment.images &&
                      data.newDevelopment.images.map((node, i) => {
                        let processedImages = JSON.stringify({})
                        if (
                          data?.newDevelopment.imagetransforms
                            ?.images_Transforms
                        ) {
                          processedImages =
                            data.newDevelopment.imagetransforms
                              .images_Transforms
                        }
                        return (
                          <div className="slide-item img-zoom" key={i}>
                            <ImageTransform
                              imagesources={node.url}
                              renderer="srcSet"
                              imagename="new-developments.images.detail"
                              attr={{
                                alt:
                                  data?.newDevelopment?.display_address +
                                  " - Espace",
                                className: "",
                              }}
                              imagetransformresult={processedImages}
                              id={data.newDevelopment.id}
                            />
                          </div>
                        )
                      })}
                  </Slider>

                  {/* Property image lightbox */}
                  {isOpen && (
                    <Lightbox
                      mainSrc={propertyLightImages[photoIndex]}
                      nextSrc={
                        propertyLightImages[
                          (photoIndex + 1) % propertyLightImages.length
                        ]
                      }
                      prevSrc={
                        propertyLightImages[
                          (photoIndex + propertyLightImages.length - 1) %
                            propertyLightImages.length
                        ]
                      }
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + propertyLightImages.length - 1) %
                            propertyLightImages.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex(
                          (photoIndex + 1) % propertyLightImages.length
                        )
                      }
                    />
                  )}
                  {/* Property image lightbox */}
                  <div className="property-btn-container">
                    <ul className="property-btn-container-list">
                      <li>
                        {data.newDevelopment.images && (
                          <div className="property-btns">
                            <Button
                              type="button"
                              variant="link"
                              className="photos_box"
                              onClick={e => openPropertyImage(e, 0)}
                            >
                              <i className="icon-camera"></i>{" "}
                              {photoIndexFloorplan + 1}/
                              {data.newDevelopment.images.length}
                            </Button>
                          </div>
                        )}
                      </li>
                      <li>
                        {data.newDevelopment.video && (
                          <div className="property-btns tour-btn">
                            <Button
                              type="button"
                              variant="link"
                              className="photos_box"
                              onClick={() => {
                                if (
                                  !data.newDevelopment.video.includes("360")
                                ) {
                                  playVideo()
                                  showVideo(true)
                                } else {
                                  show360Tour(true)
                                }
                              }}
                            >
                              <i className="icon-videocam"></i>{" "}
                              <span className="d-none d-md-inline-block text-uppercase">
                                {data.newDevelopment.video.includes("360")
                                  ? "360 tour"
                                  : "360 tour"}
                              </span>
                            </Button>
                          </div>
                        )}
                      </li>
                      <li>
                        {data.newDevelopment.floorplan.length > 0 && (
                          <div className="property-btns plan-btn">
                            <Button
                              type="button"
                              variant="link"
                              className="photos_box"
                              onClick={e => openPropertyImageFloor(e, 0)}
                            >
                              <i className="icon-floorplan"></i>{" "}
                              <span className="d-none d-md-inline-block text-uppercase">
                                floorplan
                              </span>
                            </Button>
                          </div>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Property floorplan image lightbox */}
              {isOpenFloor && (
                <Lightbox
                  mainSrc={propertyLightImagesFloorplan[photoIndex]}
                  nextSrc={
                    propertyLightImagesFloorplan[
                      (photoIndex + 1) % propertyLightImagesFloorplan.length
                    ]
                  }
                  prevSrc={
                    propertyLightImagesFloorplan[
                      (photoIndex + propertyLightImagesFloorplan.length - 1) %
                        propertyLightImagesFloorplan.length
                    ]
                  }
                  onCloseRequest={() => setIsOpenFloor(false)}
                  onMovePrevRequest={() =>
                    setPhotoIndex(
                      (photoIndex + propertyLightImagesFloorplan.length - 1) %
                        propertyLightImagesFloorplan.length
                    )
                  }
                  onMoveNextRequest={() =>
                    setPhotoIndex(
                      (photoIndex + 1) % propertyLightImagesFloorplan.length
                    )
                  }
                />
              )}
              {/* Property floorplan image lightbox */}

              <Container>
                <ScrollAnimation animateIn="fadeInUp" animateOnce>
                  <div className="search-property-details">
                    <div className="d-xl-flex justify-content-between align-items-center">
                      <h1 className="title-txt">
                        {data.newDevelopment.project ||
                          data.newDevelopment.display_address}
                      </h1>
                      <ul className="cards-btns d-flex align-items-center">
                        <li>
                          <a
                            onClick={() => {
                              setPropertyName(data?.newDevelopment?.title)
                              setPropertyUrl(
                                `/off-plan-property/${props?.params?.property_slug}`
                              )
                              setEmailModal(true)
                            }}
                            className="btn email-btn subscribe-btn media-btn"
                          >
                            <i className="icon-mail"></i>
                            <span className="">email</span>
                          </a>
                        </li>
                        {(negotiator?.Phone || data?.siteConfig) && (
                          <>
                            <li>
                              <a
                                href={`tel:${data.siteConfig.Office_Phone}`}
                                className="btn subscribe-btn media-btn"
                              >
                                <i className="icon-mobile"></i>
                                <span className="d-inline-block">call</span>
                              </a>
                            </li>
                            <li>
                              <a
                                href={`https://api.whatsapp.com/send?phone=${removePlus(data.siteConfig.Office_Whatsapp)}`}
                                target="_blank"
                                className="btn subscribe-btn media-btn"
                              >
                                <i className="icon-whatsapp"></i>
                                <span className="d-none d-md-inline-block">
                                  whatsapp
                                </span>
                              </a>
                            </li>
                          </>
                        )}
                        {/* {negotiator?.Phone &&
                                                <li>
                                                    <a href={`tel:` + negotiator?.Phone} className="btn subscribe-btn media-btn">
                                                        <i className="icon-mobile"></i>
                                                        <span className="d-inline-block">call</span>
                                                    </a>
                                                </li>
                                            }
                                            {negotiator?.Phone &&
                                                <li>
                                                    <a href={`https://api.whatsapp.com/send?phone=${removePlus(negotiator?.Phone)}`} target="_blank" className="btn subscribe-btn media-btn">
                                                        <i className="icon-whatsapp"></i>
                                                        <span className="d-none d-md-inline-block">
                                                            whatsapp
                                                        </span>
                                                    </a>
                                                </li>
                                            } */}
                      </ul>
                    </div>
                    <p className="card-desc">{data?.newDevelopment?.title}</p>
                    <div className="property-price"></div>
                    <div className="property-overview">
                      <ul className="desc-list d-md-flex flex-wrap">
                        {data.newDevelopment.price && (
                          <li>
                            <span className="txt-desc">Price from:</span>
                            <span className="desc-value price"> 
                              {priceQualifier(data.newDevelopment.price_qualifier, data.newDevelopment.price)}  
                            </span>
                          </li>
                        )}
                        {data.newDevelopment.area && (
                          <li>
                            <span className="txt-desc">Location:</span>
                            <span className="desc-value">
                              {data.newDevelopment.area &&
                                data.newDevelopment.area}
                            </span>
                          </li>
                        )}
                        {data.newDevelopment.developer && (
                          <li>
                            <span className="txt-desc">Developer:</span>
                            <span className="desc-value">
                              {data.newDevelopment.developer &&
                                data.newDevelopment.developer}
                            </span>
                          </li>
                        )}
                        {data.newDevelopment.bedroom && (
                          <li>
                            <span className="txt-desc">Bedrooms:</span>
                            <span className="desc-value">
                              {" "}
                              {data.newDevelopment.bedroom &&
                                data.newDevelopment.bedroom}
                            </span>
                          </li>
                        )}
                        {data.newDevelopment.floorarea_min && (
                          <li>
                            <span className="txt-desc">Size:</span>
                            <span className="desc-value">
                              {" "}
                              {data.newDevelopment.floorarea_min &&
                                data.newDevelopment.floorarea_min.toLocaleString()}{" "}
                              {data.newDevelopment.floorarea_type &&
                                data.newDevelopment.floorarea_type}
                            </span>
                          </li>
                        )}
                        <li>
                          {!isEmpty(negotiators) &&
                            negotiators.map(n => {
                              return (
                                <div className="contact-agent">
                                  <div className="contact-agent-photo">
                                    <img
                                      src={AgentImg}
                                      alt={`${n?.Name}, Negotiator - Espace`}
                                    />
                                  </div>
                                  <div className="contact-agent-info">
                                    <span className="contact-agent-name">
                                      {n.Name && n.Name}
                                    </span>
                                    <a
                                      href={`tel:` + n.Phone}
                                      className="contact-agent-number"
                                    >
                                      {n.Phone && n.Phone}
                                    </a>
                                  </div>
                                </div>
                              )
                            })}
                        </li>
                      </ul>
                    </div>
                  </div>
                </ScrollAnimation>
              </Container>
            </div>

            <div
              className={
                scroll
                  ? "property-details-menu-wrapper active"
                  : "property-details-menu-wrapper"
              }
            >
              <Container>
                <div className="d-flex justify-content-between property-details-wrapper">
                  <ul className="list-inline property-details-menu-list">
                    {data?.newDevelopment.accomadation_summary?.length > 0 && (
                      <li className="list-inline-item">
                        <a
                          href="javascript:void(0)"
                          className="active propertyAmenities"
                          onClick={propertyAmenities}
                        >
                          Amenities
                        </a>
                      </li>
                    )}
                    {data?.newDevelopment.description && (
                      <li className="list-inline-item">
                        <a
                          href="javascript:void(0)"
                          className="propertyDescription"
                          onClick={propertyDescription}
                        >
                          Description
                        </a>
                      </li>
                    )}
                    {data?.newDevelopment.video?.length > 0 && (
                      <li className="list-inline-item">
                        <a
                          href="javascript:void(0)"
                          className="propertyVideo"
                          onClick={() => {
                            if (!data.newDevelopment.video.includes("360")) {
                              playVideo()
                              showVideo(true)
                            } else {
                              show360Tour(true)
                            }
                          }}
                          // onClick={() => !data.newDevelopment.video.includes("360") ? showVideo(true) : show360Tour(true)}
                        >
                          Video
                        </a>
                      </li>
                    )}
                    {data?.newDevelopment.latitude && (
                      <li className="list-inline-item">
                        <a
                          href="javascript:void(0)"
                          className="propertyLocation"
                          onClick={propertyLocation}
                        >
                          Location
                        </a>
                      </li>
                    )}
                    {data?.newDevelopment.floorplan?.length > 0 && (
                      <li className="list-inline-item">
                        <a
                          href="javascript:void(0)"
                          className="propertyFloorplan"
                          onClick={e => openPropertyImageFloor(e, 0)}
                        >
                          Floorplan
                        </a>
                      </li>
                    )}
                  </ul>

                  <ul className="cards-btns d-flex align-items-center">
                    <li className="share-space social-share-wrapper">
                      <a
                        href="javascript:void(0)"
                        className="d-flex align-items-center share-btn"
                        onClick={openShareicons}
                      >
                        <i className="icon-share"></i> Share
                      </a>
                      {Shareicons && (
                        <div
                          onClick={openShareicons}
                          className="property-share-social-icons2 mobile-details-socila-share"
                        >
                          <FacebookShareButton
                            onClick={() => trackerShare("FacebookShareButton")}
                            url={shareurl}
                            className="my-share-button facebook-share"
                          >
                            <FacebookIcon
                              size={32}
                              round={false}
                              borderRadius={`10`}
                            />
                          </FacebookShareButton>
                          <TwitterShareButton
                            onClick={() => trackerShare("TwitterShareButton")}
                            url={shareurl}
                            className="my-share-button twitter-share"
                          >
                            <TwitterIcon
                              size={32}
                              round={false}
                              borderRadius={`10`}
                            />
                          </TwitterShareButton>
                          <LinkedinShareButton
                            onClick={() => trackerShare("LinkedinShareButton")}
                            url={shareurl}
                            className="my-share-button linkedin-share"
                          >
                            <LinkedinIcon
                              size={32}
                              round={false}
                              borderRadius={`10`}
                            />
                          </LinkedinShareButton>
                          <WhatsappShareButton
                            onClick={() => trackerShare("WhatsappShareButton")}
                            url={shareurl}
                            className="my-share-button whatsapp-share"
                          >
                            <WhatsappIcon
                              size={32}
                              round={false}
                              borderRadius={`10`}
                            />
                          </WhatsappShareButton>
                          <EmailShareButton
                            url="#"
                            onClick={() => {
                              setPropertyName(data?.newDevelopment?.title)
                              setPropertyUrl(
                                `/off-plan-property/${props?.params?.property_slug}`
                              )
                              setPropertyId(data?.newDevelopment?.objectID)
                              setFriendEmailModal(true)
                              trackerShare("SendToFriend")
                            }}
                            className="my-share-button email-share"
                          >
                            <EmailIcon
                              size={32}
                              round={false}
                              borderRadius={`10`}
                            />
                          </EmailShareButton>
                        </div>
                      )}
                    </li>

                    <li>
                      <a
                        className="btn email-btn subscribe-btn media-btn"
                        onClick={() => {
                          setPropertyName(data?.newDevelopment?.title)
                          setPropertyUrl(
                            `/off-plan-property/${props?.params?.property_slug}`
                          )
                          setEmailModal(true)
                        }}
                      >
                        <i className="icon-mail"></i>
                        <span className="">email</span>
                      </a>
                    </li>

                    {(negotiator?.Phone || data?.siteConfig) && (
                      <>
                        <li>
                          <a
                            href={`tel:${
                              negotiator?.Phone
                                ? negotiator?.Phone
                                : data.siteConfig.Office_Phone
                            }`}
                            className="btn subscribe-btn media-btn media-btn-small"
                          >
                            <i className="icon-mobile"></i>
                            <span className="d-none d-md-inline-block">
                              call
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            href={`https://api.whatsapp.com/send?phone=${removePlus(
                              negotiator?.Phone
                                ? negotiator?.Phone
                                : data.siteConfig.Office_Phone
                            )}`}
                            target="_blank"
                            className="btn subscribe-btn media-btn media-btn-small"
                          >
                            <i className="icon-whatsapp"></i>
                          </a>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </Container>
            </div>

            <KeyFeature propertyData={data.newDevelopment} offplan={true} />
            <Amenities propertyData={data.newDevelopment} />
            <Container>
              <PhotoGallery
                propertyData={data.newDevelopment}
                enlargeFun={openPropertyImage}
              />
              <Payment propertyData={data.newDevelopment} />
              <MediaList
                propertyData={data.newDevelopment}
                showVideo={
                  !data.newDevelopment?.video?.includes("360")
                    ? showVideo
                    : show360Tour
                }
                playVideo={playVideo}
                data={data.newDevelopment}
                enlargeImgFun={openPropertyImage}
                enlargeImgFloorFun={openPropertyImageFloor}
              />
            </Container>

            {/* // TODO: Need to check do we wanted this module here? */}
            {negotiator?.Email && <PropertyContact email={negotiator?.Email} />}

            <div
              className="property-details-landing-map area-guide-map mb-5"
              id="property-details-map"
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.GATSBY_GOOGLE_MAP_KEY,
                }}
                // bootstrapURLKeys={{ key: "" }}
                defaultCenter={{
                  lat: 25.13797501888234,
                  lng: 55.12747016889561,
                }}
                defaultZoom={11}
              >
                <Marker
                  lat={data?.newDevelopment?.latitude}
                  lng={data?.newDevelopment?.longitude}
                />
              </GoogleMapReact>
            </div>

            <div className="calculate-valuation">
              <Container>
                <div className="property-form d-xl-flex justify-content-between">
                  <MortgageCalculator property={data?.newDevelopment} />
                  <PropertyValuation />
                </div>
              </Container>
            </div>
            <SimilarProperties
              property={data.newDevelopment}
              collection={`new-developments`}
              title={`Similar Offplan Projects in Dubai`}
              readMore="/off-plan-developments/in-dubai"
              off_plan={true}
            />
          </div>
        )}
        <Footer popularSearch="Popular_Static" />
      </div>
      <Modal
        size="lg"
        show={emailModal}
        onHide={() => setEmailModal(false)}
        centered
        className="contact-form-sec"
      >
        <div className="contact-form subsribe-form">
          <Modal.Header closeButton>
            <Modal.Title as="h2">Book a Viewing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BookAViewing
              property_name={property_name}
              property_url={process.env.GATSBY_SITE_URL + property_url}
            />
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        size="lg"
        show={friendEmailModal}
        onHide={() => setFriendEmailModal(false)}
        centered
        className="contact-form-sec"
      >
        <div className="contact-form subsribe-form">
          <Modal.Header closeButton>
            <Modal.Title as="h2">Send to Friend</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SendFriendFormPage
              property_title={property_name}
              property_url={process.env.GATSBY_SITE_URL + property_url}
              page_url={process.env.GATSBY_SITE_URL + property_url}
              property_img={data?.newDevelopment?.images[0].url}
              property_id={property_id}
              email={email}
            />
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        size="xl"
        show={video360}
        onHide={() => show360Tour(false)}
        centered
        className="w-100 modal360"
      >
        <Modal.Body>
          {data?.newDevelopment?.video && (
            <iframe
              style={{ height: "90vh", width: "100%" }}
              src={data?.newDevelopment?.virtual_tour}
            />
          )}
        </Modal.Body>
        <button className="close-btn" onClick={() => show360Tour(false)}>
          <i className="icon-close w-100 h-100" />
        </button>
      </Modal>
      <div ref={videoRef}>
        {data && data.newDevelopment && (
          <PlayVideo
            isOpen={video}
            isCloseFunction={() => showVideo(false)}
            videoId={getVideoId(data?.newDevelopment?.video)}
            isAutoPlay={1}
          />
        )}
        <strong
          className="play-btn close-icon"
          onClick={() => {
            showVideo(false)
            videoRef.current.classList.remove("fullscreen-video")
          }}
        >
          <i className="icon-close" />
        </strong>
      </div>
    </React.Fragment>
  )
}

export default React.memo(PropertyDetailsTemplate)
