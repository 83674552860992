import { Link } from "gatsby";
import { isEmpty } from "lodash";
import * as React from "react";
import { Button, Form, Container, Row, Col, Breadcrumb } from "react-bootstrap";
import "./PaymentPlan.scss";
import ScrollAnimation from "react-animate-on-scroll";

const PaymentPlan = (props) => {

    let propertyData = props?.propertyData
    let payments = [];
    for (let i = 1; i <= 10; i++) {
        if (!isEmpty(propertyData[`installment_${i}`]) && !isEmpty(propertyData[`payment_date_${i}`])) {
            payments.push({
                installment: propertyData[`installment_${i}`],
                paymentDate:propertyData[`payment_date_${i}`]
            })
        }
    }

  if (payments.length === 0)
    return <></>

  return (
    <React.Fragment>
      <div className="payment-plan">
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
            <div className="stories-head">
                <h2 className="main-title">Payment Plan</h2>
            </div>
            <ul className="payment-list d-md-flex flex-wrap justify-content-between">
                {payments.map((payment, i) =>
                    <li>
                        <strong className="d-block">{payment.paymentDate}</strong>
                        <span className="d-block">{payment.installment}</span>
                    </li>
                )}
            </ul>
        </ScrollAnimation>
      </div>

    </React.Fragment>
  );
};
export default PaymentPlan;
